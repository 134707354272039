import Cookie from "js.cookie/dst/Cookie"

$(function () {
  initSidebarButtons()
  initMenuDropdown()
})

function initSidebarButtons() {
  $("#close-sidebar").click(function () {
    closeSidebar()
  })
  $("#show-sidebar").click(function () {
    $(".page-wrapper").addClass("toggled")
    Cookie.set('is_sidebar_shown', true)
  })
  if (window.innerWidth < 768) {
    closeSidebar()
  }
}

function closeSidebar() {
  $(".page-wrapper").removeClass("toggled")
  Cookie.set('is_sidebar_shown', false)
}

function initMenuDropdown() {
  $(".sidebar-dropdown > a").click(function () {
    $(".sidebar-submenu").slideUp(200)
    if (
      $(this)
        .parent()
        .hasClass("active")
    ) {
      $(".sidebar-dropdown").removeClass("active")
      $(this)
        .parent()
        .removeClass("active")
    } else {
      $(".sidebar-dropdown").removeClass("active")
      $(this)
        .next(".sidebar-submenu")
        .slideDown(200)
      $(this)
        .parent()
        .addClass("active")
    }
  })
}
